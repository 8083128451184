import React from "react";
import classes from "../css/NavBar.module.css";

const Navbar = () => {
  return (
    <nav class="navbar navbar-expand-xl navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand fs-2" href="/">Kasoccer<span id={classes.tag}>#3952</span></a>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarBasic" aria-controls="navbarBasic" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse align-text-right" id="navbarBasic">
        <ul class="navbar-nav ms-auto mb-2 mb-xl-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="https://discord.com/users/683927124784054328" target="_blank" rel="noopener noreferrer"><i id={classes.discord_icon} class="fab fa-discord fs-3"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="https://github.com/kasoccer" target="_blank" rel="noopener noreferrer"><i id={classes.github_icon}  class="fab fa-github fs-3"></i></a>
          </li>
          <li class="nav-item">
          <a class="nav-link active" href="mailto:hi@kasoccer.me" ><i id={classes.mail_icon}  class="fas fa-envelope"></i></a>
          </li>
        </ul>
       
      </div>
    </div>
  </nav>


  );
};

export default Navbar;
