import classes from '../css/TopG.module.css'
import NavBar from '../components/NavBar'
function TopG() {

    return(
        
        <div className={classes.container}>
            <NavBar/>
            <h1 className={classes.hero}>TOP G'S ONLY</h1>
            <div>
                <img className={classes.bugatti} src='https://i.ytimg.com/vi/CeOdquN9EpQ/maxresdefault.jpg' alt='what colors your bugatti???' ></img>
            </div>

            <p className={classes.bugattiText}>What colours your bugatti?</p>
            <img className={classes.TOPG} src='https://biooverview.com/wp-content/uploads/2022/04/Tate-.jpg' alt='top-g'></img>
            
            
        </div>
    )
}

export default TopG