import { Route, Routes } from 'react-router-dom'

import HomePage from './pages/HomePage';
import NotFound from './pages/404'
import TopG from './pages/TopG'
function App() {
  return(
    <Routes>
      <Route path='/' exact element={<HomePage/>}/>
      <Route path='*' element={<NotFound/>}></Route>
      <Route path='/topg' element={<TopG/>}></Route>
    </Routes>
  )
}
export default App;
