import NavBar from '../components/NavBar'
import classes from "../css/HomePage.module.css";
import Activity from '../components/Activity.js';

function HomePage() {
  return (
    <div>
      <NavBar />

      <div className={classes.container}>
        <h1 className={classes.name}>Kasoccer</h1>
        <h3 className={classes.desc}>Student <span id={classes.developerBlue}>/</span> Developer from Australia</h3>
      </div>

      <Activity/>
    </div>
  );
}

export default HomePage;
