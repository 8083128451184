import { useLanyard } from "react-use-lanyard";
import classes from "../css/Spotify.module.css";
function Spotify() {
  const user = useLanyard({
    userId: "683927124784054328",
    socket: true
  });
   try {

    if(user.status.activities[0].name === "Visual Studio Code") {
      let activity;
      user.status.activities[0].name === "Visual Studio Code" ? activity = user.status.activities[0] : activity = user.statys.activities[1]
      let imgURL = `https://dcdn.dstn.to/app-icons/${activity.application_id}`
      return(
        <div className={classes.container}>
          
          <p className={classes.song}>
          <img src={imgURL} id={classes.activity_logo}></img>
              Playing <a style={{color: "#0078d7"}}>{activity.name}</a>, <a>{activity.details}</a>
          </p>
        </div>
      )
    }
  
    if (user.status?.listening_to_spotify === true) {
      return (
        <div className={classes.container}>
          <p className={classes.song}>
            <i id={classes.spotify_logo} class="fab fa-spotify"></i> Listening
            to{" "}
            <a
              href={`https://open.spotify.com/track/${user.status?.spotify.track_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {user.status?.spotify.song}
            </a>{" "}
            by {user.status?.spotify.artist}{" "}
          </p>
        </div>
      );
    } 
    
    return (
      <div className={classes.container}>
        <p className={classes.song}>
          <i id={classes.spotify_logo} class="fab fa-spotify"></i> Not listening
          to anything right now...
        </p>
      </div>
    );
  } catch (e) {
    console.log(e);
    return (
      <div className={classes.container}>
        <p className={classes.song}>
          <i id={classes.spotify_logo} class="fab fa-spotify"></i> Loading
          song...
        </p>
      </div>
    );
  }
}

export default Spotify;
