import react from 'react'
import classes from '../css/404.module.css'
function NotFound() {
    document.title = "404 - Page Not Found"
    return(
        <div>
            <div className={classes.container}>
                <h1 className={classes.header}>404</h1>
                <h3 className={classes.text}>Page Not Found</h3>
                <a href='/' className={classes.btn}>Home</a>
            </div>
        </div>
    )
}

export default NotFound;